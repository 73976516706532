@font-face {
  font-family: 'Jost-Regular';
  src: url('./Assets/Jost/static/Jost-Regular.ttf');
}

@font-face {
  font-family: 'Jost-Bold';
  src: url('./Assets/Jost/static/Jost-Bold.ttf');
}

@font-face {
  font-family: 'Urbanist';
  src: url('./Assets/Urbanist/static/Urbanist-Regular.ttf');
}

@font-face {
  font-family: 'Urbanist-Italic';
  src: url('./Assets/Urbanist/static/Urbanist-Italic.ttf');
}
:root {
  --primary: #0e0e0e;
  --secondary: #28e98c;
  --tertiary: #171718;
  --light: #f4f4f4;
  --jost-regular: 'Jost-Regular';
  --jostbold: 'Jost-Bold';
  --urbanist: 'Urbanist';
  --urbanist-italic: 'Urbanist-Italic';
  background-color: #0e0e0e;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
body , .body{
  /* border: 1px solid var(--secondary); */
  min-height: 100svh;
  background-color: transparent !important;
  color: var(--light) !important;
  display: flex  !important;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap !important;
  align-items: center !important;
  justify-content: center !important;
  justify-self: center !important;
  align-content: center !important;
  align-self: center !important;
  overflow-x: hidden !important;

}

/* WebKit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--primary); /* Background of the scrollbar track */
  border-radius: 15px  !important;

}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 15px  !important;

}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the scrollbar thumb on hover */
}

/* Firefox */
* {
  scrollbar-width:thin; /* Width of the scrollbar */
  scrollbar-color: #888 var(--primary); /* Color of the scrollbar thumb and track */
}

p{
  font-family: var(--urbanist);
}

a {
  text-decoration: none !important; /* Remove underline */
  color: inherit !important; /* Inherit color from parent */
  background: none !important; /* Remove background */
  border: none !important; /* Remove border */
  padding: 0 !important; /* Remove padding */
  margin: 0 !important; /* Remove margin */
  font: inherit !important; /* Inherit font properties */
  cursor: pointer !important; /* Set cursor to pointer */
}

.App {
  width: 95% !important;
  /* border: 2px solid white; */
  align-self: center;
}