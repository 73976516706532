.Project-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: start;
    margin-top: 50px;
    width: 95%;
}
.Projects-main-component-class {
    margin-top: 150px;
}

@media (max-width: 768px) {
    .Project-container {
        flex-direction: column;
        align-items: center;
    }
}