.About-main-component-class {
  background-image: url("../../Assets/circles.svg"); /* Replace with the correct path to your image */
  background-size: cover;
  background-position: top; /* Centers the background image */
  width: 100%;
  height: 100vh; /* Adjust the height as needed */
  margin-top: 70px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

}

.highlighted-text {
  color: var(--secondary);
  font-family: var(--urbanist-italic);
}

.Main-content-container {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
}
.About-Title-text,
.About-text-container span {
  font-family: var(--urbanist);

 
}


.paragraph-text-span {
  display: flex;

  width: 500px !important;
}

.content{
    display: flex;
    align-items: center  !important;
    justify-content: space-between !important;
}

.Main-content-container{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

/* Mobile styling */
@media (max-width: 768px) {
  .About-main-component-class {
    background-image: none;
    background-color: var(--primary);
    height: 100%;
    margin-top: 0;
  }

  .Main-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
  }
  .About-Title-text{
    margin-top: 50px;
  }
  .paragraph-text-span {
    display: flex;
    max-width: 350px !important;
    text-align: justify;
  }

  .img-about{
    margin-top: 20px;
    width: 300px;
  }
}