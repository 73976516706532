.Services-content-class{
    display:flex;
    justify-content: space-between;
    align-items: center !important;
    gap:10%;
    /* background-image: url("../../Assets/eclipse.svg");
    object-fit: cover;
    background-position: center; */
}
.Services-main-component-class {
      background-image: url("../../Assets/eclipse.svg");
    object-fit: cover;
    background-position: right 35% bottom 45%;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    .Services-content-class{
        flex-direction: column;
        gap:1px !important;

    }
    .Services-main-component-class{
        flex-direction: column;
    }
    .Services-content-item-class{
        margin-top: 50px !important;
    }
}

.Slider-wrapper-main{
    max-width: 100vw !important;
}