.single-project-main-component-class {
    
    margin: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    background-color: var(--tertiary);
    width: 300px;
    min-height: 400px;
}
.info-class{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px;
}

.category-class {
    color: var(--secondary);
    width: 100%;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
}
.name-project-main{
    font-family: var(--jostbold);
}

.Stack-project-main {
    color: var(--secondary);

}