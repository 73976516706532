.Contact-main-component-class{
    display: flex;
    justify-content: space-between;
    height: 80svh;
    margin-top: 150px;
}
@media (max-width: 768px){
    .Contact-main-component-class{
        flex-direction: column;
    }
    
}
.Input-field-class:focus{
    outline: none;
}
.Input-field-class {
    background-color: transparent;
    border: none;
    border: 1px solid rgb(200, 197, 197);
    border-radius: 5px;
    padding: 10px;
    color: white;
    
}