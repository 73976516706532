.Footer-main-component-class {
  background-color: #f8f9fa;
  padding: 20px 0;
  color: var(--tertiary);
  text-align: center;
  width: 100vw !important;
  border-radius: 5px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.Footer-main-component-class p {
  font-family: var(--urbanist);
}